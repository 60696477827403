<template>
  <div class="Operation">
    <div class="top-title">
      <div class="top-text">社区会员列表</div>
        <el-form ref="form" label-width="100px">
          <div class="top-box">
         <el-form-item label="标题" >
                  <el-input v-model="seeks.a"></el-input>
          </el-form-item>

              <el-button
                type="primary"
                class="biy-icon" icon="el-icon-check" @click="seek()"
                >搜索</el-button>
            </div>
        </el-form>
    </div>
    <div class="table-tap">
       <ul>
          <li v-for="(item,i) in taplist" :key="i"> <span @click="tap(item)" :class="classactive == item.value?'active':''">{{item.label}}</span></li>
        </ul>
    </div>
    <!-- 选择列表  表格-->           
    <div class="table-form">
      <el-table :data="list" v-loading="loading">
         <el-table-column label="序号" width="70px">
           <template slot-scope="scope"> {{scope.$index+1}} </template>
          </el-table-column>
        <el-table-column label="图片/视频"  width="700"> 
            <template slot-scope="scope">
              <div v-if="scope.row.catalog == '0' ? true : false ">
                  <img class="scopeimg" v-for="(item , i) in scope.row.urls" :key="i" :index="item.id" :src="item" height="80" />
              </div>
              <div class="video-div" v-if="scope.row.catalog == '1' ? true : false ">
                  <a class="video-a" :href="scope.row.urls[0]" target="_blank"></a>
                    <video width="250" height="180" controls>
                        <source :src="scope.row.urls[0]" type="video/mp4">
                    </video>
              </div>
            </template>
        </el-table-column>
       
        <el-table-column prop="bsr_title" label="标题"> </el-table-column>
        <el-table-column prop="wk_nickname" label="发布者"> </el-table-column>
        <el-table-column label="操作" width="200" class="table-form-btn">
          <template slot-scope="scope">
            <el-button
              @click="deleted(scope.row)"
              class="table-form-btn"
              type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
           <!-- 分页器 -->
      <div class="block" style="margin-top: 15px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>

    </div>
  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
      // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      // 分页
      currentPage: 1, // 当前页码
      total: 10,
      pageSize: 10, // 每页的数据条数
      //加载
      loading: this.$common.loading,

      classactive:0,
      taplist:fakelist.community.one,

      list:[],
      seeks:{}
    };
  },
  created() {
     this.getSong()
  },
  mounted() {},

  methods: {

    //查询接口
      async getSong(){
				let res = await apiurl.fetchPageData({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        pageIndex: this.currentPage,
        pageSize: this.pageSize,

        bizCatalog:'CommunityEntity',
        handleMode:'fetchallShare',
        query:{
          catalog:this.classactive,
          recruitTitle:this.seeks.a
        }, })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.list = res.Result.pageData
           this.total = res.Result.count
           this.loading = false
      },
  // 选项卡
      tap(e){
      this.currentPage = 1;
       this.classactive = e.value
       this.getSong();
      },

      //确认弹窗
       deleted(row){
         this.listpar = row
          this.$confirm('是否确认删除', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.deleteds()
          }).catch(action => {
            console.log("取消");
          })
      },
      //确认
    async  deleteds(){
    	let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家

        bizCatalog:'CommunityEntity',
        handleMode:'removeShare',
        shareId:this.listpar.id,

       })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.dialogFormVisible = false
         this.getSong();
         this.$alert('删除成功', '消息提示', {confirmButtonText: '确定'});
      },


    // 搜索栏
    seek(){
      this.currentPage = 1;
       this.getSong()
    },

    // 分页 每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSong();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSong();
    },
  },
  computed: {},
};
</script>


<style  scoped>
.video-div{
  width: 100%;
  height: 100%;
  position: relative;
}
.video-a{
  position: absolute;
    width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 99;
  background: rgba(0, 0, 0, 0.062);
  width: 250px;
}
.table-tap{
  width: 98%;
  margin: 0 auto;
  padding: 30px 0;
  border-bottom: 2px solid rgb(255, 187, 0);
}
.table-tap ul{
  display: flex;
  justify-content: left;
}
.table-tap ul li{
  width: 150px;
  text-align: center;
}
.table-tap ul li span{
font-size: 18px;
cursor: pointer;
transition: all .3s;
}
.table-tap ul li span.active{
  color: rgb(255, 187, 0);
  font-size: 16px;
}

@import "../../../assets/css/liststyle.css";
</style>